import Login from "../Components/Login/Login";
import logo from "../logo_full.png";
import classes from "./Login.module.scss";

const LoginPage = () => {
	return (
		<main className={classes.main}>
			<img className={classes.logo} src={logo} alt="Logo webui" />
			<Login />
		</main>
	);
};
export default LoginPage;
