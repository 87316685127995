import classes from "./Load.module.scss";
const Loader = () => {
	return (
		<>
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="800">
				<defs>
					<filter id="goo">
						<feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
						<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" />
					</filter>
				</defs>
			</svg>
			<div className={classes.gooey__loader}>
				<div className={classes.gooey__loader__dot}></div>
				<div className={classes.gooey__loader__dot}></div>
				<div className={classes.gooey__loader__dot}></div>
				<div className={classes.gooey__loader__dot}></div>
			</div>
		</>
	);
};
export default Loader;
