import logo from "../logo.png";
import classes from "./Layout.module.scss";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const logout = () => {
	window.localStorage.removeItem("jwt");
	window.location = "/auth";
};
const Layout = (props) => {
	return (
		<>
			<header>
				<img src={logo} alt="Logo" />
				{/* <nav>
					<ul>
						<li>Téléchargements</li>
						<li>Fichiers</li>
						<li>Suivis</li>
						<li>Système</li>
					</ul>
				</nav> */}
				<FontAwesomeIcon className={classes.logout} icon={faPowerOff} onClick={() => logout()} />
			</header>
			{props.children}
		</>
	);
};
export default Layout;
