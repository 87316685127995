import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faClock,
	faGaugeHigh,
	faPlay,
	faCheck,
	faExclamation,
	faRightLeft,
	faFileArrowDown,
	faPause,
	faTrash,
	faSpinner,
	faMicrochip,
	faLink,
	faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./Downloads.module.scss";
import Modal from "../Modal/Modal";
import { io } from "socket.io-client";
import Loader from "../Loader/Loader";

const socket = io("wss://api.webui.fr");

const formatData = (data, setData) => {
	return data.map((element) => {
		if (["active", "paused"].includes(element.status)) {
			return (
				<div
					key={element.gid}
					className={classes.item}
					onContextMenu={handleContextMenu}
					style={{
						background: `linear-gradient(to right, rgba(85, 239, 196,0.3) ${element.percent}%, transparent ${element.percent}%)`,
					}}
				>
					<div className={classes.infos}>
						<span className={classes.name}>{element.name}</span>
						<div className={classes.badges}>
							<div className={classes.badge}>
								<FontAwesomeIcon icon={faGaugeHigh} />
								<span>
									{!bytesToSize(element.downloadSpeed)
										? "--"
										: bytesToSize(element.downloadSpeed) + "/s"}
								</span>
							</div>
							<div className={classes.badge}>
								<FontAwesomeIcon icon={faClock} />
								<span>{toDateTime(element.status) ? toDateTime(element.timeleft) : "--"}</span>
							</div>

							<div className={classes.badge}>
								<FontAwesomeIcon icon={faFileArrowDown} />
								<span>{!bytesToSize(element.length) ? "--" : bytesToSize(element.length)}</span>
							</div>
						</div>
					</div>
					<div className={classes.controls} onClick={handleClickContextMenu}>
						{element.status === "active" ? (
							<FontAwesomeIcon
								icon={faPause}
								className={classes.pause}
								onClick={() => {
									setData(undefined);
									socket.emit("pauseGID", { gid: element.gid });
								}}
							/>
						) : (
							<FontAwesomeIcon
								icon={faPlay}
								className={classes.play}
								onClick={() => {
									setData(undefined);
									socket.emit("unpauseGID", { gid: element.gid });
								}}
							/>
						)}
						<FontAwesomeIcon
							icon={faTrash}
							className={classes.trash}
							onClick={() => {
								setData(undefined);
								socket.emit("rmGID", { gid: element.gid });
							}}
						/>
					</div>
				</div>
			);
		} else if (element.status === "waiting") {
			return (
				<div key={element.gid} className={classes.itemWaiting}>
					<FontAwesomeIcon icon={faSpinner} className={classes.waiting} />
					<span className={classes.name}>{element.name}</span>
				</div>
			);
		} else if (element.status === "pending") {
			return (
				<div key={element.gid} className={classes.itemWaiting}>
					<FontAwesomeIcon icon={faMicrochip} className={classes.queued} />
					<span className={classes.name}>{element.name}</span>
				</div>
			);
		} else if (element.status === "complete") {
			return (
				<div key={element.gid} className={classes.completeItem}>
					<span className={classes.name}>{element.name}</span>
				</div>
			);
		} else if (element.status === "error") {
			return (
				<div key={element.gid} className={classes.errorItem}>
					<span className={classes.name}>
						{element.name}
						<small className={classes.error}>{element.error}</small>
					</span>
				</div>
			);
		} else return "";
	});
};
const bytesToSize = (bytes) => {
	bytes = parseInt(bytes);
	if (bytes === 0) return false;

	var k = 1000;
	var sizes = ["B", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"];
	var i = Math.floor(Math.log(bytes) / Math.log(k));
	return (bytes / Math.pow(k, i)).toPrecision(3) + sizes[i];
};

const toDateTime = (secs) => {
	const SECONDS_IN_HOUR = 3600;

	if (["active", "complete", "paused", "waiting"].includes(secs)) {
		return secs;
	}

	const secNum = parseInt(secs, 10);
	if (Number.isNaN(secNum)) {
		return "--";
	}

	const hours = Math.floor(secNum / SECONDS_IN_HOUR);
	const minutes = Math.floor((secNum % SECONDS_IN_HOUR) / 60);
	const seconds = secNum % 60;

	const paddedHours = hours.toString().padStart(2, "0");
	const paddedMinutes = minutes.toString().padStart(2, "0");
	const paddedSeconds = seconds.toString().padStart(2, "0");

	if (hours > 24) {
		return "--";
	}

	return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

const handleAddClick = () => {
	let els = document.querySelectorAll("." + classes.goo__btn);
	for (let i = 0; i < els.length; i++) {
		els[i].classList.toggle(classes.goo__active);
	}
	document.querySelector("." + classes.goo__toggler).classList.toggle(classes.goo__active);
};

const handleContextMenu = (e) => {
	e.preventDefault();
	const elem = e.currentTarget.querySelector("." + classes.controls);
	elem.style.display = "flex";
};
const handleClickContextMenu = (e) => {
	e.preventDefault();
	const elem = e.currentTarget;
	elem.style.display = "";
};

const Downloads = () => {
	const [data, setData] = useState(undefined);
	const [onglet, setOnglet] = useState("active");
	const [modal, setModal] = useState(false);
	const [device, setDevice] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			let width = window.innerWidth;
			if (width < 768 && device !== "mobile") setDevice("mobile");
			else if (width >= 768 && width < 1366 && device !== "tablet") setDevice("tablet");
			else if (width >= 1366 && device !== "desktop") setDevice("desktop");
		};
		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [device]);

	useEffect(() => {
		function onDisconnect() {
			setData(undefined);
		}

		function onDownloadEvent(data) {
			if (data.waiting) data.active = [...(data.active ?? []), ...data.waiting];
			if (data.pending) data.active = [...(data.active ?? []), ...data.pending];
			setData(data);
		}
		function onNotify(data) {
			toast.info(data, {
				position: "top-left",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		}
		function onError(data) {
			toast.error(data, {
				position: "top-left",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		}

		socket.on("downloads", onDownloadEvent);
		socket.on("notify", onNotify);
		socket.on("error", (data) => onError);
		socket.on("disconnect", onDisconnect);

		return () => {
			socket.off("disconnect", onDisconnect);
			socket.off("downloads", onDownloadEvent);
			socket.off("notify", onNotify);
			socket.off("error", onError);
		};
	}, []);

	const Menu = () => (
		<ul className={classes.onglets}>
			<li
				className={`${classes.onglet} ${onglet === "active" ? classes.active : null}`}
				onClick={() => setOnglet("active")}
			>
				<FontAwesomeIcon icon={faRightLeft} />
				<span>Actives</span>
			</li>
			<li
				className={`${classes.onglet} ${onglet === "error" ? classes.active : null}`}
				onClick={() => setOnglet("error")}
			>
				<FontAwesomeIcon icon={faExclamation} />
				<span>Erreurs</span>
			</li>
			<li
				className={`${classes.onglet} ${onglet === "complete" ? classes.active : null}`}
				onClick={() => setOnglet("complete")}
			>
				<FontAwesomeIcon icon={faCheck} />
				<span>Terminés</span>
			</li>

			<li>
				<div className={classes.goo}>
					<div className={classes.goo__wrapper}>
						<button className={classes.goo__toggler} onClick={() => handleAddClick()}>
							<FontAwesomeIcon className={classes.goo__icon} icon={faPlus} />
						</button>
						<ul className={classes.goo__list}>
							<li className={classes.goo__item}>
								<button
									className={`${classes.goo__btn} ${classes.goo__btn__1}`}
									onClick={() => {
										handleAddClick();
										setModal("links");
									}}
								>
									{device === "mobile" ? <FontAwesomeIcon icon={faLink} /> : "Liens"}
								</button>
							</li>
							<li className={classes.goo__item}>
								<button
									className={`${classes.goo__btn} ${classes.goo__btn__2}`}
									onClick={() => {
										handleAddClick();
										setModal("torrents");
									}}
								>
									{device === "mobile" ? <FontAwesomeIcon icon={faFileAlt} /> : "Torrents"}
								</button>
							</li>
						</ul>
					</div>
				</div>
			</li>
		</ul>
	);

	return (
		<>
			<main>
				{modal ? <Modal onClose={() => setModal(false)} modalType={modal} socket={socket} /> : null}
				{data === undefined || !device ? (
					<Loader />
				) : (
					<div className={classes.container}>
						{device !== "mobile" ? <Menu /> : null}
						<div className={classes.items}>
							{data[onglet]?.length ? (
								formatData(data[onglet], setData)
							) : (
								<div className={classes.nothing}>Aucun téléchargement.</div>
							)}
						</div>
					</div>
				)}
				<ToastContainer />
			</main>
			{device === "mobile" ? (
				<footer className={classes.footer}>
					<Menu />
				</footer>
			) : null}
		</>
	);
};

export default Downloads;
