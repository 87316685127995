import classes from "./Login.module.scss";
import { useEffect, useState } from "react";
import axios from "axios";
// import Autofill from "../Autofill/Autofill";

const genLabel = (text) => {
	return text.split("").map((char, id) => (
		<span key={Math.floor(Math.random() * Date.now())} className={classes.labelChar} style={{ "--index": id }}>
			{char}
		</span>
	));
};

const Login = () => {
	const [username, setUsername] = useState("");
	const [passwd, setPasswd] = useState("");
	// const [showPwd, setShowPwd] = useState(false);

	useEffect(() => {
		let token = window.localStorage.getItem("jwt");
		if (!token) return;
		axios
			.post("https://api.webui.fr/tokenVerify", { token: token })
			.then((res) => {
				if (!res.data.success) return;
				// window.location = "/";
			})
			.catch((err) => console.log(err));
	}, []);

	const handleChange = (e, setter) => setter(e.target.value);
	const handleSubmit = (e) => {
		e.preventDefault();
		if (!username || !passwd) return;
		axios
			.post("https://api.webui.fr/auth", { username: username, passwd: passwd })
			.then((res) => {
				if (!res.data.success) return;
				window.localStorage.setItem("jwt", res.data.token);
				window.location = "/";
			})
			.catch((err) => console.log(err));
	};

	return (
		<>
			<form id="formLogin" onSubmit={(e) => handleSubmit(e)}>
				<div className={classes.waveGroup}>
					<input
						type="text"
						className={classes.input}
						name="username"
						value={username}
						onChange={(e) => handleChange(e, setUsername)}
						onAnimationStart={(e) => (!username ? (e.target.value = "") : null)}
						// onAnimationStart={(e) => Autofill(e.target)}
						required
					/>
					<span className={classes.bar}></span>
					<label className={classes.label}>{genLabel("Utilisateur")}</label>
				</div>
				<div className={classes.waveGroup}>
					<input
						// type={showPwd ? "text" : "password"}
						type="password"
						className={classes.input}
						name="passwd"
						value={passwd}
						onChange={(e) => handleChange(e, setPasswd)}
						onAnimationStart={(e) => (!passwd ? (e.target.value = "") : null)}
						required
					/>
					<span className={classes.bar}></span>
					<label className={classes.label}>{genLabel("Mot de passe")}</label>
				</div>
				<button className={classes.connection} disabled={username && passwd ? false : true}>
					Connexion
				</button>
			</form>
		</>
	);
};
export default Login;
