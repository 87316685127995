import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import classes from "./Modal.module.scss";
import { useContext, useState } from "react";
import { UserContext } from "../../Context";

const wizz = (elem) => {
	elem.classList.add(classes.error);
	setTimeout(() => {
		elem.classList.remove(classes.error);
	}, 500);
};

const handleChange = (e) => {
	let elem = e.currentTarget;
	let span = elem.parentNode.querySelector("span");
	let c = elem.files.length;
	if (c) span.textContent = elem.files.length + (c !== 1 ? " torrents" : " torrent");
	else span.textContent = "Glissez vos torrents";
};

const dragOver = (e) => {
	e.preventDefault();
	e.currentTarget.classList.add(classes.dropZoneOver);
};
const dragLeave = (e) => {
	e.preventDefault();
	e.currentTarget.classList.remove(classes.dropZoneOver);
};

const dragDrop = (e) => {
	e.preventDefault();
	const files = e.dataTransfer.files;
	const inputElement = document.getElementById("filesInput");
	const elem = e.currentTarget;
	let span = elem.querySelector("span");
	let dropMsg;
	if (files.length) {
		[...files].forEach((file, i) => {
			if (file.type !== "application/x-bittorrent") {
				dropMsg = "Seulement des torrents.";
			}
		});
		if (!dropMsg) {
			inputElement.files = e.dataTransfer.files;
			let c = e.dataTransfer.items.length;
			dropMsg = c + (c !== 1 ? " torrents" : " torrent");
		}
	} else {
		inputElement.value = "";
		dropMsg = "Aucun fichier déposé.";
	}
	elem.classList.remove(classes.dropZoneOver);
	span.textContent = dropMsg;
};

const sendLinks = (socket, path, close) => {
	if (path === null) return wizz(document.querySelector("." + classes.categories));

	let textarea = document.querySelector("." + classes.links);
	let links = [];

	textarea.value.split("\n").forEach((val) => {
		if (val.substring(0, 4) === "http") links.push(val);
	});
	textarea.value = links.join("\n");

	if (!links.length) return wizz(textarea);

	let data = { path: path, links: links };
	socket.emit("newDDL", data);
	close();
};
const sendTorrents = async (socket, path, close) => {
	if (path === null) return wizz(document.querySelector("." + classes.categories));

	const fileInput = document.querySelector("#filesInput").files;

	if (!fileInput.length) return wizz(document.querySelector("." + classes.dropZone));

	const files = [];
	const promises = [];
	for (let i = 0; i < fileInput.length; i++) {
		const file = fileInput[i];
		const reader = new FileReader();
		const promise = new Promise((resolve, reject) => {
			reader.onloadend = async () => {
				const buffer = new Uint8Array(reader.result);
				files.push({
					name: file.name,
					buffer: buffer,
				});
				resolve();
			};
			reader.readAsArrayBuffer(file);
		});
		promises.push(promise);
	}
	await Promise.all(promises);

	socket.emit("newTorrent", { path: path, files: files });
	close();
};

const Modal = (props) => {
	const [cat, setCat] = useState(false);
	const [selected, setSelected] = useState(null);
	const [selectedText, setSelectedText] = useState("Selectionnez une catégorie");
	const userInfo = useContext(UserContext);
	const username = userInfo.username.toLowerCase();
	return (
		<>
			<div id="overlay" className={classes.overlay} onClick={(e) => props.onClose()}></div>
			<div className={classes.modal}>
				<div className={classes.modalHeader}>
					<div className={classes.close} onClick={(e) => props.onClose()}>
						<FontAwesomeIcon icon={faXmark} />
					</div>
				</div>
				<div className={classes.modalBody}>
					<div className={classes.categories}>
						<div className={classes.select}>
							<span id="category" className={classes.category} onClick={() => setCat(!cat)}>
								{selectedText}
							</span>
							<div className={classes.options} style={cat ? { display: "flex" } : { display: "none" }}>
								<span
									onClick={() => {
										setSelected(username === "anthony" ? 5 : 0);
										setSelectedText("Films");
										setCat(!cat);
									}}
								>
									Films
								</span>
								{username !== "anthony" ? (
									<span
										onClick={() => {
											setSelected(1);
											setSelectedText("Films Animés");
											setCat(!cat);
										}}
									>
										Films Animés
									</span>
								) : null}
								{username !== "anthony" ? (
									<span
										onClick={() => {
											setSelected(7);
											setSelectedText("Spectacles");
											setCat(!cat);
										}}
									>
										Spectacles
									</span>
								) : null}
								<span
									onClick={() => {
										setSelected(username === "anthony" ? 6 : 2);
										setSelectedText("Séries TV");
										setCat(!cat);
									}}
								>
									Séries TV
								</span>
								{username !== "anthony" ? (
									<span
										onClick={() => {
											setSelected(3);
											setSelectedText("Mangas");
											setCat(!cat);
										}}
									>
										Mangas
									</span>
								) : null}
								{username !== "anthony" ? (
									<span
										onClick={() => {
											setSelected(4);
											setSelectedText("Kids");
											setCat(!cat);
										}}
									>
										Kids
									</span>
								) : null}
							</div>
						</div>
					</div>
					{props.modalType === "torrents" ? (
						<div
							className={classes.dropZone}
							onDragOver={(e) => dragOver(e)}
							onDragLeave={(e) => dragLeave(e)}
							onDragEnd={(e) => dragLeave(e)}
							onDrop={(e) => dragDrop(e)}
							onClick={() => document.getElementById("filesInput").click()}
						>
							<input
								type="file"
								name="files[]"
								id="filesInput"
								onChange={(e) => handleChange(e)}
								multiple
								accept=".torrent"
							/>
							<span>Glissez vos torrents</span>
						</div>
					) : (
						<textarea
							className={classes.links}
							placeholder="Ajoutez des liens"
							spellCheck="false"
						></textarea>
					)}
				</div>
				<div className={classes.modalFooter}>
					<button className={classes.bgRed} onClick={(e) => props.onClose()}>
						Annuler
					</button>
					<button
						className={classes.bgBlue}
						onClick={() =>
							props.modalType === "torrents"
								? sendTorrents(props.socket, selected, props.onClose)
								: sendLinks(props.socket, selected, props.onClose)
						}
					>
						Ajouter
					</button>
				</div>
			</div>
		</>
	);
};
export default Modal;
