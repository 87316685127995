import Downloads from "../Components/Downloads/Downloads";
import Layout from "./Layout";

const DownloadsPage = () => {
	return (
		<Layout>
			<Downloads />
		</Layout>
	);
};
export default DownloadsPage;
