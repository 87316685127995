import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "../Pages/Login";
import DownloadsPage from "../Pages/Downloads";
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "./Loader/Loader";
import jwt_decode from "jwt-decode";
import { UserContext } from "../Context";

const Router = () => {
	const [isAuth, setIsAuth] = useState(undefined);
	const [userInfo, setUserInfo] = useState({});

	const PrivateRoute = ({ page }) =>
		isAuth ? page : isAuth === undefined ? <Loader /> : <Navigate to="/auth" replace />;

	useEffect(() => {
		let token = window.localStorage.getItem("jwt");
		if (!token) return setIsAuth(false);
		(async () => {
			let res = await axios.post("https://api.webui.fr/tokenVerify", { token: token });
			setIsAuth(res.data.success ? true : false);
			const decode = jwt_decode(token);
			if (res.data.success) setUserInfo(decode);
		})();
	}, []);

	return (
		<Routes>
			<Route path="/">
				<Route
					path="/"
					element={
						<PrivateRoute
							page={
								<UserContext.Provider value={userInfo}>
									<DownloadsPage />
								</UserContext.Provider>
							}
						/>
					}
				/>
				{/* <Route index element={() => isAuth(DownloadsPage)} /> */}
				<Route path="/auth" element={<LoginPage />} />
				<Route path="*" element={<Navigate to="/" replace />} />
			</Route>
		</Routes>
	);
};

export default Router;
